<template>
  <div>
    <FollowerBar />
    <v-tabs v-model="activeTab" centered>
      <v-tab
        v-for="tab of tabs"
        :key="tab.id"
        :to="tab.route"
        @click="setActiveTab(tab.route)"
        exact
      >
        {{ $t(tab.name) }}
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import FollowerBar from '../components/FollowerBar.vue'
export default {
  components: {
    FollowerBar,
  },
  data() {
    return {
      activeTab: `/bca/${this.$route.params.id}`,
    }
  },
  methods: {
    setActiveTab(route) {
      this.activeTab = route
    },
  },
  computed: {
    tabs() {
      return [
        {
          id: 1,
          name: this.$i18n.t('title.claim'),
          route: `/bca/${this.$route.params.id}`,
        },
        {
          id: 2,
          name: this.$i18n.t('title.messages'),
          route: `/bca/${this.$route.params.id}/messages`,
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped></style>
